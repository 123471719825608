<template>
  <div></div>
</template>

<script>
export default {
  name: 'Index',
  layout: 'empty',
  mounted() {
    this.$router.replace('/editor/');
  },
};
</script>

<style>

</style>
